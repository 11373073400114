var OneSignal = window.OneSignal || [];

$(document).on('pushConfigReady', function() {

	OneSignal.push(['init', params.OneSignalConfig]);

	OneSignal.push(function() {
		//Works in console... TODO: Test it out.
		//OneSignal.notifyButton.message.enqueue('test');

		OneSignal.on('permissionPromptDisplay', function() {
			displayPermissionPromptToplayer();
		});

		OneSignal.on('notificationPermissionChange', function(event) {
			hidePermissionPromptToplayer();
		});

		if (OneSignal.isPushNotificationsSupported()) {
			OneSignal.getNotificationPermission(function(permission) {
				if (permission != 'denied') {
					OneSignal.isPushNotificationsEnabled(function(isEnabled) {
						if(
							!isEnabled &&
							!$('#subscribe-toplayer').hasClass('visible') &&
							params.popoverConfig.enabled &&
							sessionStorage.getItem('onesignal-pageview-count') > params.popoverConfig.pageViewThreshold &&
							sessionStorage.getItem('ONESIGNAL_HTTP_PROMPT_SHOWN') != 'true'
						) {
							displaySubscribePopover();
						}
					});
				}
			});
		}
	});

});
