var translationsObject = {
	pl: {
		welcomeNotification_title: 'Powiadomienia aktywne',
		welcomeNotification_message: 'Pomyślnie włączyłeś(aś) powiadomienia.',
		notifyButton_textunsubscribed: 'Otrzymuj powiadomienia',
		notifyButton_tip_state_subscribed: 'Otrzymujesz powiadomienia',
		notifyButton_tip_state_blocked: 'Zablokowałeś(aś) otrzymywanie powiadomień',
		notifyButtonmessage_prenotify: 'Kliknij i otrzymuj powiadomienia! Zawsze możesz je wyłączyć.',
		notifyButtonmessage_action_subscribed: 'Dziękujemy za subskrybcję!',
		notifyButtonmessage_action_resubscribed: 'Będziesz otrzymywać powiadomienia',
		notifyButtonmessage_action_unsubscribed: 'Nie będziesz już otrzymywać powiadomień',
		notifyButtondia_log_main_title: 'Zarządzaj powiadomieniami',
		notifyButtondia_log_main_button_subscribe: 'WŁĄCZ POWIADOMIENIA',
		notifyButtondia_log_main_button_unsubscribe: 'WYŁĄCZ POWIADOMIENIA',
		notifyButtondia_log_blocked_title: 'Odblokuj powiadomienia',
		notifyButtondia_log_blocked_message: 'Postępuj zgodnie z poniższymi wskazówkami aby odblokować powiadomienia:',
		promptOptions_actionMessage: 'Możemy wysyłać Ci powiadomienia z najnowszymi informacjami?<br>Zawsze możesz je wyłączyć.',
		promptOptions_acceptButtonText: 'OK',
		promptOptions_cancelButtonText: 'Nie, dziękuję',
		permissionPromptToplayerConfig_text: 'Prawie gotowe! <br>Potwierdź subskrypcję w swojej przeglądarce.',
		permissionPromptToplayerConfig_subtext: 'Powiadomienia wysyłamy tylko dla <br>najważniejszych informacji i zawsze możesz je wyłączyć.',
		permissionPromptToplayerConfig_browserInfo_desktop: 'Aby włączyć powiadomienia na urządzeniu mobilnym, <br>odwiedź tę stronę na telefonie.',
		permissionPromptToplayerConfig_browserInfo_mobile: 'Aby włączyć powiadomienia na komputerze, <br>odwiedź tę stronę w przeglądarce komputerowej.',
		subscribeToplayerConfig_header: 'Chcesz wiedzieć więcej?',
		subscribeToplayerConfig_textArray: [
			'Bądź na bieżąco i otrzymuj powiadomienia z najnowszymi informacjami!',
			'Powiadomienia wysyłamy tylko dla najważniejszych informacji.'
			],
		subscribeToplayerConfig_footerText: 'Zawsze możesz je wyłączyć.',
		displaySubscribeToplayer_readMore: 'Dowiedz się więcej', /*w pliku subscribe-toplayer.js*/
		displaySubscribeToplayer_button_cta: 'Włącz powiadomienia' /*w pliku subscribe-toplayer.js*/
	},
	cs: {
		welcomeNotification_title: 'Aktivní oznámení',
		welcomeNotification_message: 'Oznámení byla úspěšně aktivována.',
		notifyButton_textunsubscribed: 'Získejte oznámení',
		notifyButton_tip_state_subscribed: 'Obdržíte oznámení',
		notifyButton_tip_state_blocked: 'Zablokovali jste oznámení',
		notifyButtonmessage_prenotify: 'Klikni a přijď oznámení! Vždy je můžete vypnout.',
		notifyButtonmessage_action_subscribed: 'Děkujeme za přihlášení!',
		notifyButtonmessage_action_resubscribed: 'Obdržíte oznámení',
		notifyButtonmessage_action_unsubscribed: 'Již nebudete přijímat oznámení',
		notifyButtondia_log_main_title: 'Správa oznámení',
		notifyButtondia_log_main_button_subscribe: 'POVOLENÍ OZNÁMENÍ',
		notifyButtondia_log_main_button_unsubscribe: 'ZRUŠIT OZNÁMENÍ',
		notifyButtondia_log_blocked_title: 'Odemknout oznámení',
		notifyButtondia_log_blocked_message: 'Chcete-li odemknout oznámení, postupujte podle pokynů níže:',
		promptOptions_actionMessage: 'Můžeme vám posílat oznámení. <br> Vždy je můžete vypnout.',
		promptOptions_acceptButtonText: 'OK',
		promptOptions_cancelButtonText: 'Ne, díky',
		permissionPromptToplayerConfig_text: 'Skoro připraven! <br> Potvrďte předplatné ve vašem prohlížeči.',
		permissionPromptToplayerConfig_subtext: 'Oznámení jsou zasílána pouze pro nejdůležitější informace a vždy je můžete vypnout.',
		permissionPromptToplayerConfig_browserInfo_desktop: 'Chcete-li povolit oznámení v mobilním zařízení, navštivte tuto stránku v telefonu.',
		permissionPromptToplayerConfig_browserInfo_mobile: 'Chcete-li povolit oznámení v počítači, navštivte tuto stránku v prohlížeči počítače.',
		subscribeToplayerConfig_header: 'Chcete se dozvědět víc?',
		subscribeToplayerConfig_textArray: [
			'Buďte informováni a obdržíte oznámení s nejnovějšími zprávami!',
			'Posíláme pouze oznámení o nejdůležitějších informacích.'
			],
		subscribeToplayerConfig_footerText: 'Vždy je můžete vypnout.',
		displaySubscribeToplayer_readMore: 'Další informace', /*w pliku subscribe-toplayer.js*/
		displaySubscribeToplayer_button_cta: 'Zapněte oznámení' /*w pliku subscribe-toplayer.js*/
	},
	sk: {
		welcomeNotification_title: 'Aktívne upozornenia',
		welcomeNotification_message: 'Upozornenia boli úspešne aktivované.',
		notifyButton_textunsubscribed: 'Dostať upozornenia',
		notifyButton_tip_state_subscribed: 'Zobrazujú sa upozornenia',
		notifyButton_tip_state_blocked: 'Zablokovali ste prijímanie upozornení',
		notifyButtonmessage_prenotify: 'Kliknite a prijímajte upozornenia! Vždy ich môžete vypnúť.',
		notifyButtonmessage_action_subscribed: 'Ďakujeme, že ste sa prihlásili na odber!',
		notifyButtonmessage_action_resubscribed: 'Obdržíte upozornenia',
		notifyButtonmessage_action_unsubscribed: 'Už nebudete dostávať upozornenia',
		notifyButtondia_log_main_title: 'Správa upozornení',
		notifyButtondia_log_main_button_subscribe: 'POVOLENIE OZNÁMENIA',
		notifyButtondia_log_main_button_unsubscribe: 'ZRUŠIŤ NOTIFIKÁCIE',
		notifyButtondia_log_blocked_title: 'Odomknutie upozornení',
		notifyButtondia_log_blocked_message: 'Ak chcete odomknúť upozornenia, postupujte podľa pokynov nižšie:',
		promptOptions_actionMessage: 'Môžeme vám posielať upozornenia. <br> Môžete ich vždy vypnúť.',
		promptOptions_acceptButtonText: 'OK',
		promptOptions_cancelButtonText: 'Nie ďakujem',
		permissionPromptToplayerConfig_text: 'Skoro pripravený! <br> Potvrdenie predplatného vo vašom prehliadači.',
		permissionPromptToplayerConfig_subtext: 'Upozornenia sa odosielajú len pre najdôležitejšie informácie a vždy ich môžete vypnúť.',
		permissionPromptToplayerConfig_browserInfo_desktop: 'Ak chcete povoliť upozornenia vo vašom mobilnom zariadení, navštívte túto stránku v telefóne.',
		permissionPromptToplayerConfig_browserInfo_mobile: 'Ak chcete povoliť upozornenia v počítači, navštívte túto stránku v prehliadači počítača.',
		subscribeToplayerConfig_header: 'Chcete sa dozvedieť viac?',
		subscribeToplayerConfig_textArray: [
			'Zostaňte aktuálne a prijímajte upozornenia s najnovšími správami!',
			'Posielame iba upozornenia na najdôležitejšie informácie.'
			],
		subscribeToplayerConfig_footerText: 'Vždy ich môžete vypnúť.',
		displaySubscribeToplayer_readMore: 'Uč sa viace', /*w pliku subscribe-toplayer.js*/
		displaySubscribeToplayer_button_cta: 'Zapnite upozornenia' /*w pliku subscribe-toplayer.js*/
	},
	en: {
		welcomeNotification_title: 'Active notifications',
		welcomeNotification_message: 'Notifications have been successfully enabled.',
		notifyButton_textunsubscribed: 'Get notifications',
		notifyButton_tip_state_subscribed: 'You get notifications',
		notifyButton_tip_state_blocked: 'You have blocked receiving notifications',
		notifyButtonmessage_prenotify: 'Click and receive notifications! You can always turn them off.',
		notifyButtonmessage_action_subscribed: 'Thank you for subscribing!',
		notifyButtonmessage_action_resubscribed: 'You will receive notifications',
		notifyButtonmessage_action_unsubscribed: 'You will no longer receive notifications',
		notifyButtondia_log_main_title: 'Manage notifications',
		notifyButtondia_log_main_button_subscribe: 'ENABLE NOTIFICATIONS',
		notifyButtondia_log_main_button_unsubscribe: 'DISABLE NOTIFICATIONS',
		notifyButtondia_log_blocked_title: 'Unlock notifications',
		notifyButtondia_log_blocked_message: 'Follow the instructions below to unlock notifications:',
		promptOptions_actionMessage: 'We can send you notifications. <br> You can always turn them off.',
		promptOptions_acceptButtonText: 'OK',
		promptOptions_cancelButtonText: 'No thanks',
		permissionPromptToplayerConfig_text: 'Almost ready! <br> Confirm subscription in your browser.',
		permissionPromptToplayerConfig_subtext: 'Notifications are sent only for the most important information and you can always turn them off.',
		permissionPromptToplayerConfig_browserInfo_desktop: 'To enable notifications on your mobile device, visit this page on your phone.',
		permissionPromptToplayerConfig_browserInfo_mobile: 'To enable notifications on your computer, visit this page in a computer browser.',
		subscribeToplayerConfig_header: 'Do you want to know more?',
		subscribeToplayerConfig_textArray: [
			'Stay up to date and receive notifications with the latest news!',
			'We only send notifications for the most important information.'
			],
		subscribeToplayerConfig_footerText: 'You can always turn them off.',
		displaySubscribeToplayer_readMore: 'Learn more', /*w pliku subscribe-toplayer.js*/
		displaySubscribeToplayer_button_cta: 'Turn on notifications' /*w pliku subscribe-toplayer.js*/
	}
}

var translation = translationsObject[window.globalLang] || translationsObject['en'];
