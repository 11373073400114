function displayPermissionPromptToplayer() {
	var osClass;
	var subtext = '';
	var brandImage = (params.brandConfig.logoURL) ? '<img class="brand-image" src="'+params.brandConfig.logoURL+'" />' : '';

	if(bowser.windows || bowser.mac) {
		osClass = 'desktop';
	} else if(bowser.android) {
		osClass = 'mobile';
	}

	if(!sessionStorage.getItem('subscribeToplayerShown')) {
		subtext = '<span class="subtext">'+params.permissionPromptToplayerConfig.subtext+'</span>';
	}

	if(osClass) {
		if(!(osClass == 'mobile' && bowser.name.toLowerCase() == 'chrome')) {
			$('body').append(
				'<div id="permission-prompt-toplayer" class="'+osClass+' '+bowser.name.toLowerCase()+'" style="background: '+params.brandConfig.background+'; color: '+params.brandConfig.foregroundColor+';">'+
					brandImage+
					'<span class="text">'+params.permissionPromptToplayerConfig.text+'</span>'+
					subtext+
					'<span class="subtext second">'+params.permissionPromptToplayerConfig.browserInfo[osClass]+'</span>'+
					'<span class="arrow">'+
						'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 43.1 85.9" style="enable-background:new 0 0 43.1 85.9;" xml:space="preserve"><path stroke-linecap="round" stroke-linejoin="round" class="st0 draw-arrow" style="stroke: '+params.brandConfig.foregroundColor+';" d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1" /><path stroke-linecap="round" stroke-linejoin="round" class="draw-arrow tail-1" style="stroke: '+params.brandConfig.foregroundColor+';" d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58" /><path stroke-linecap="round" stroke-linejoin="round" class="draw-arrow tail-2" style="stroke: '+params.brandConfig.foregroundColor+';" d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9" /></svg>'+
					'</span>'+
				'</div>'
			);

			setTimeout(function() {
				$('#permission-prompt-toplayer').addClass('visible')
			}, 5);
		}
	}
}

function hidePermissionPromptToplayer() {
	if($('#permission-prompt-toplayer').length) {
		$('#permission-prompt-toplayer').removeClass('visible');
		setTimeout(function() {$('#permission-prompt-toplayer').remove()}, 1400); //TODO: Replace with animationEnd event or something.
	}
}
