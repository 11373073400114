if (typeof window.jQuery != 'undefined') {

	var params = {
		OneSignalConfig: {
			appId: 'e90fafdb-51be-4c59-9fa5-602149d40c93',
			autoRegister: false,
			welcomeNotification: {
				title: translation.welcomeNotification_title,
				message: translation.welcomeNotification_message
			},
			notifyButton: {
				enable: true,
				size: 'medium', // One of 'small', 'medium', or 'large'
				theme: 'default', // One of 'default' (red-white) or 'inverse" (white-red)
				position: 'bottom-left', // Either 'bottom-left' or 'bottom-right'. Now set to left because of frequent use of Tawk.to
				offset: {
					bottom: '20px',
					left: '20px', // Only applied if bottom-left
					right: '0px' // Only applied if bottom-right
				},
				prenotify: true, // Show an icon with 1 unread message for first-time site visitors
				showCredit: false, // Hide the OneSignal logo
				text: {
					'tip.state.unsubscribed': translation.notifyButton_textunsubscribed,
					'tip.state.subscribed': translation.notifyButton_tip_state_subscribed,
					'tip.state.blocked': translation.notifyButton_tip_state_blocked,
					'message.prenotify': translation.notifyButtonmessage_prenotify,
					'message.action.subscribed': translation.notifyButtonmessage_action_subscribed,
					'message.action.resubscribed': translation.notifyButtonmessage_action_resubscribed,
					'message.action.unsubscribed': translation.notifyButtonmessage_action_unsubscribed,
					'dialog.main.title': translation.notifyButtondia_log_main_title,
					'dialog.main.button.subscribe': translation.notifyButtondia_log_main_button_subscribe,
					'dialog.main.button.unsubscribe': translation.notifyButtondia_log_main_button_unsubscribe,
					'dialog.blocked.title': translation.notifyButtondia_log_blocked_title,
					'dialog.blocked.message': translation.notifyButtondia_log_blocked_message
				},
				colors: { // Customize the colors of the main button and dialog popup button
					'circle.background': 'rgb(127,127,127)',
					'circle.foreground': 'white',
					'badge.background': 'rgb(127,127,127)',
					'badge.foreground': 'white',
					'badge.bordercolor': 'white',
					'pulse.color': 'white',
					'dialog.button.background.hovering': 'rgb(127,127,127)',
					'dialog.button.background.active': 'rgb(127,127,127)',
					'dialog.button.background': 'rgb(0,0,0)',
					'dialog.button.foreground': 'white'
				}
			},
			promptOptions: {
				actionMessage: translation.promptOptions_actionMessage, // Subtitle, limited to 90 characters
				acceptButtonText: translation.promptOptions_acceptButtonText, // Accept button text, limited to 15 characters
				cancelButtonText: translation.promptOptions_cancelButtonText // Cancel button text, limited to 15 characters
			}
		},
		permissionPromptToplayerConfig: {
			text: translation.permissionPromptToplayerConfig_text, //Aby otrzymywać powiadomienia, <br>zezwól na nie w swojej przeglądarce.
			subtext: translation.permissionPromptToplayerConfig_subtext,
			browserInfo: {
				desktop: translation.permissionPromptToplayerConfig_browserInfo_desktop,
				mobile: translation.permissionPromptToplayerConfig_browserInfo_mobile
			}
		},
		subscribeToplayerConfig: {
			imageURL: null,
			header: translation.subscribeToplayerConfig_header,
			textArray: translation.subscribeToplayerConfig_textArray,
			footerText: translation.subscribeToplayerConfig_footerText,
			readMoreURL: null
		},
		popoverConfig: {
			enabled: true,
			pageViewThreshold: 2
		},
		brandConfig: {
			background: 'rgba(0, 0, 0, 0.75)',
			foregroundColor: '#fff',
			accentColor: '#888',
			logoURL: false
		}
	}

	//Merge with passed params
	params = merge.recursive(true, params, window.pushConfig);

	setTimeout(function() {
		$(document).trigger('pushConfigReady');
	}, 0);

}
