var OneSignal = window.OneSignal || [];

if(typeof dataLayer != 'undefined') {
	OneSignal.push(function() {

		OneSignal.on('permissionPromptDisplay', function() {
			dataLayer.push({'event': 'permissionPromptDisplay'});
		});

		OneSignal.on('subscribeToplayerShown', function() {
			dataLayer.push({'event': 'subscribeToplayerShown'});
		});

		OneSignal.on('popoverShown', function() {
			dataLayer.push({'event': 'popoverShown'});
		});

		OneSignal.on('popoverAllowClick', function() {
			dataLayer.push({'event': 'popoverAllowClick'});
		});

		OneSignal.on('popoverCancelClick', function() {
			dataLayer.push({'event': 'popoverCancelClick'});
		});

		OneSignal.on('notificationPermissionChange', function(event) {
			dataLayer.push({
				'event': 'notificationPermissionChange',
				'permission': event.to //granted|denied|default
			});
		});

		OneSignal.on('subscriptionChange', function (isSubscribed) {
			dataLayer.push({
				'event': 'subscriptionChange',
				'subscription': isSubscribed
			});
	    });

		OneSignal.on('notificationDisplay', function(event) {
			dataLayer.push({
				'event': 'notificationDisplay',
				'id': event.id,
				'data': event.data
			});
		});

		OneSignal.on('notificationDismiss', function(event) {
			dataLayer.push({
				'event': 'notificationDismiss',
				'id': event.id,
				'data': event.data
			});
		});

	});
}
