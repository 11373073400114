function displaySubscribeToplayer() {
	var osClass;
	var text = '';
	var image = '';
	var brandImage = (params.brandConfig.logoURL) ? '<img class="brand-image" src="'+params.brandConfig.logoURL+'" />' : '';
	var footerText = (params.subscribeToplayerConfig.footerText) ? '<span class="footer-text">'+params.subscribeToplayerConfig.footerText+'</span>' : '';
	var readMore = (params.subscribeToplayerConfig.readMoreURL) ? '<a href="'+params.subscribeToplayerConfig.readMoreURL+'" class="footer-text read-more">'+translation.displaySubscribeToplayer_readMore+' &rsaquo;</a>' : '';

	if(bowser.windows || bowser.mac) {
		osClass = 'desktop';
	} else if(bowser.android) {
		osClass = 'mobile';
	}

	if(Array.isArray(params.subscribeToplayerConfig.textArray)) {
		$.each(params.subscribeToplayerConfig.textArray, function(i, line) {
			text += '<span class="text">'+line+'</span>';
		});
	}

	if(params.subscribeToplayerConfig.imageURL) {
		image = '<img src="'+params.subscribeToplayerConfig.imageURL+'" />';
	} else {
		image = '<svg class="onesignal-bell-svg" xmlns="http://www.w3.org/2000/svg" width="99.7" height="99.7" viewBox="0 0 99.7 99.7"><circle class="background" style="fill: '+params.brandConfig.accentColor+';" cx="49.9" cy="49.9" r="49.9"/><path class="foreground" d="M50.1 66.2H27.7s-2-.2-2-2.1c0-1.9 1.7-2 1.7-2s6.7-3.2 6.7-5.5S33 52.7 33 43.3s6-16.6 13.2-16.6c0 0 1-2.4 3.9-2.4 2.8 0 3.8 2.4 3.8 2.4 7.2 0 13.2 7.2 13.2 16.6s-1 11-1 13.3c0 2.3 6.7 5.5 6.7 5.5s1.7.1 1.7 2c0 1.8-2.1 2.1-2.1 2.1H50.1zm-7.2 2.3h14.5s-1 6.3-7.2 6.3-7.3-6.3-7.3-6.3z"/><ellipse class="stroke" cx="49.9" cy="49.9" rx="37.4" ry="36.9"/></svg>';
	}

	setTimeout(function() {
		if (OneSignal.isPushNotificationsSupported()) {
			OneSignal.getNotificationPermission(function(permission) {
				if (permission != 'denied') {
					OneSignal.isPushNotificationsEnabled(function(isEnabled) {
						if(
							!isEnabled &&
							osClass &&
							!sessionStorage.getItem('subscribeToplayerShown')
						) {
							$('body').append(
								'<div id="subscribe-toplayer" class="'+osClass+' '+bowser.name.toLowerCase()+'" style="background: '+params.brandConfig.background+';">'+
									'<div class="container">'+
										'<div class="header">'+
											brandImage+
											'<span class="close">&times;</span>'+
										'</div>'+
										'<div class="image">'+
											image+
										'</div>'+
										'<div class="content">'+
											'<span class="header">'+params.subscribeToplayerConfig.header+'</span>'+
											text+
											'<span class="cta" style="background-color: '+params.brandConfig.accentColor+';">'+translation.displaySubscribeToplayer_button_cta+'</span>'+
											footerText+
											readMore+
										'</div>'+
									'</div>'+
								'</div>'
							);

							sessionStorage.setItem('subscribeToplayerShown', true);
							OneSignal.trigger('subscribeToplayerShown');

							setTimeout(function() {
								$('#subscribe-toplayer').addClass('visible')

								$('#subscribe-toplayer .cta').on('click', function() {
									$('#subscribe-toplayer .close').click();
									OneSignal.registerForPushNotifications();
								});

								$('#subscribe-toplayer .close').on('click', function() {
									$('#subscribe-toplayer').removeClass('visible');
									setTimeout(function() {$('#subscribe-toplayer').remove()}, 1400); //TODO: Replace with animationEnd event or something.
								});

							}, 5);
						}
					});
				}
			});
		}
	}, 0);

}
